import { useEffect, useState } from 'react';
import axios from 'axios';
import { ColorModeContext, useMode } from './theme';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';
import Topbar from './scenes/global/Topbar';
import Sidebar from './scenes/global/Sidebar';
import LanguageSwitcher from './components/LanguageSwitcher';
import Dashboard from './scenes/dashboard';
import Team from './scenes/team';
import Roads from './scenes/roads';
import Campaign from './scenes/campaign';
import FAQ from './scenes/faq';
import Map from './scenes/map';
import Home from './scenes/home';
import Login from './scenes/login';
import Info from './scenes/info';
import RideSafe from './scenes/ridesafe';
import Public from './scenes/public';
import Misc from './scenes/misc';
import Pathfinder from './scenes/pathfinder';
import InfoIcon from '@mui/icons-material/InfoOutlined';

if (!JSON.parse(localStorage.getItem('timeRange'))) {
  const now = Date.now(); // current time in milliseconds
  const dayInMilliseconds = 24 * 60 * 60 * 1000;
  const _fr = now - 2 * dayInMilliseconds; // convert to nanoseconds
  const _to = now - dayInMilliseconds; // convert to nanoseconds

  localStorage.setItem(
    'timeRange',
    JSON.stringify({
      from: _fr,
      to: _to,
    })
  );
}

const App = () => {
  const navigate = useNavigate();
  const [theme, colorMode] = useMode();
  const [loggedIn, setLoggedIn] = useState(() => {
    return !localStorage.getItem('user') ? false : true;
  });

  // const [email, setEmail] = useState('');
  // const [dataKey, setDataKey] = useState({});

  useEffect(() => {
    //Fetch the user email and token from local storage
    const user = JSON.parse(localStorage.getItem('user'));
    // console.log(loggedIn);
    //If the token/email does not exist, mark the user as logged out
    if (!user || !user.token || user.username === 'public') {
      setLoggedIn(false);
      console.log('App: Authenticate failed');
      return;
    } else {
      //verify user if needed
      const validate = async () => {
        const apiURL = process.env.REACT_APP_CHECK_USER;

        // const _params = {
        //   user: user.username,
        // };
        try {
          const response = await axios.get(apiURL, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          });

          if (response.status === 200) {
            setLoggedIn(true);
            console.log('Welcome user');
          } else {
            setLoggedIn(false);
            localStorage.removeItem('user');
            console.log('Checked - Authenticate failed');
            navigate('/');
            return;
          }
        } catch (err) {
          setLoggedIn(false);
          localStorage.removeItem('user');
          console.log('Checked - Authenticate failed');
          navigate('/');
          return;
        }
      };
      validate();
    }
  }, []);

  const hideSidebarRoutes = ['/', '/home', '/login'];
  const dashboardNotReady = !localStorage.getItem('user');
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div id='app' className='app'>
          {!hideSidebarRoutes.includes(useLocation().pathname) && <Sidebar loggedIn={loggedIn} />}
          <main className='content'>
            <Box display='flex' justifyContent='flex-end' p={2} alignItems={'center'}>
              <Topbar loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
              <LanguageSwitcher loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
            </Box>
            <Routes>
              <Route path='/' element={<Home loggedIn={loggedIn} setLoggedIn={setLoggedIn} />} />
              <Route path='/info' element={<Info />} />
              <Route path='/ridesafe' element={<RideSafe />} />
              <Route path='/faq' element={<FAQ />} />
              <Route path='/public' element={<Public />} />
              <Route
                key='login'
                path='/login'
                element={loggedIn ? <Navigate to='/dashboard' /> : <Login setLoggedIn={setLoggedIn} loggedIn={loggedIn} />}
              />
              {/* <Route path='/dashboard' element={loggedIn ? <Dashboard loggedIn={loggedIn} /> : <Navigate to='/' />} /> */}
              <Route
                path='/dashboard'
                element={dashboardNotReady ? <Navigate to='/home' /> : <Dashboard loggedIn={loggedIn} setLoggedIn={setLoggedIn} />}
              />
              <Route path='/team' element={loggedIn ? <Team loggedIn={loggedIn} setLoggedIn={setLoggedIn} /> : <Navigate to='/' />} />
              <Route path='/roads' element={loggedIn ? <Roads loggedIn={loggedIn} setLoggedIn={setLoggedIn} /> : <Navigate to='/' />} />
              <Route
                path='/campaign'
                element={loggedIn ? <Campaign loggedIn={loggedIn} setLoggedIn={setLoggedIn} /> : <Navigate to='/' />}
              />
              <Route path='/map' element={loggedIn ? <Map /> : <Navigate to='/' />} />
              <Route path='/misc' element={<Misc />} />
              <Route path='/pathfinder' element={<Pathfinder />} />
              <Route path='*' element={<Navigate to='/' />} />
            </Routes>
          </main>
        </div>
        <div id='notification'>
          <div id='noti-content' className='floating-div'>
            <InfoIcon color='success' sx={{ fontSize: '3em' }} />
            <p>LƯU Ý</p>
            Vui lòng sử dụng máy tính để xem nội dung dự án Đạp xe An toàn. <b>Xin cám ơn.</b>
            <hr style={{ width: '50px' }} />
            <p>NOTICE</p>
            Bike Safely app is best viewed on a PC.
            <br />
            <b>Thank you.</b>
          </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
