import { createContext, useState, useContext } from 'react';

export const langTokens = (lang) => ({
  ...(lang === 'en'
    ? {
        dashboard: {
          DASHBOARD: 'Dashboard',
          WELCOME: 'Display all information',
          TOTAL_DISTANCE: 'Total distance',
          TOTAL_DISTANCE_DESC: 'In this toolbar, you can see the total distance that the students have traveled.',
          TRAVEL: 'travelled',
          FROM: 'from',
          TO: 'to',
          DATA_COLLECTED: 'Data collected',
          DEVICES: 'devices',
          RESULT_CHARTS: 'Result',
          RESULT_NO: 'No.',
          RESULT_ROUTES: 'Monitored Routes',
          RESULT_RATIOS: 'Ratios (%)',
          RESULT_RESULTS: 'Results',
          TOTAL_TRAVEL: 'Total distance travelled',
          LOCALE: 'en-AU',
          CO2_EMISSION: 'GHG reduction',
          CO2_EMISSION_2: 'of approximately',
          CO2_UNIT: 'tonnes CO2e',
          CO2_EMISSION_DESC:
            'In this toolbar, you can see the number of metric tons of carbon dioxide equivalent (CO2e) reduced by cycling.',
          SCHOOL_INFO: 'School Info',
          SCHOOL_NAME: 'School',
          SCHOOL_ADDRESS: 'Address',
          SCHOOL_TIME: 'Time Range',
          TIME_INFO: 'Selected Time Range',
          TIME_START: 'From',
          TIME_END: 'To',
          ADDITIONAL_INFO: 'Additional information',
          CLUSTER_MAP: 'Cluster map',
          ROUTES_MAP: 'Routes map',
          FULL_MAP: 'Full-width map',
          NORMAL_MAP: 'Normal-size map',
          SHOW_SCHOOL: "Show schools' locations",
          HIDE_SCHOOL: "Hide schools' locations",
          SHOW_ROUTE: 'Show all routes',
          HIDE_ROUTE: 'Hide all routes',
          SHOW_ENDS: "Show routes' terminals",
          HIDE_ENDS: "Hide routes' terminals",
          LEGEND: 'LEGEND',
          LEGEND_ROUTE: 'Recorded routes',
          LEGEND_START: 'Starting point of a route',
          LEGEND_END: 'End point of a route',
          LEGEND_SCHOOL: "A school's location",
        },
        sidebar: {
          WELCOME: 'Welcome',
          MAP: 'Map',
          CHART: 'Chart',
          DATA: 'Data',
          OTHER: 'Other',
          CAMPAIGN: 'Schools',
          DASHBOARD: 'Dashboard',
          TEAM: 'Team',
          ROADS: 'Roads',
          FAQ: 'FAQ',
          CONTACT: 'Contact',
          INFO: 'Info',
          KNOWLEDGE: 'Knowledge',
          PUBLIC: 'Dashboard',
        },
        faq: {
          TITLE: 'FAQ',
          SUBTITLE: 'The most common questions and anwsers',
        },
        login: {
          SIGNIN: 'Log in',
          CANCEL: 'Cancel',
          USER: 'Username',
          PASS: 'Password',
          ERROR_401: 'Login failed. Please check your credentials.',
          ERROR_GENERAL: 'An error occurred during login. Please try again later.',
          ERROR_USERNAME: 'Please enter a correct username',
          ERROR_PASSWORD: 'Please enter a correct password',
        },
        map: {
          TITLE: 'Map',
          SUBTITLE: 'Routes from recorded GPS data',
          FROM: 'From',
          TO: 'To',
          LOCALE: 'en-AU',
        },
        campaign: {
          TITLE: 'Schools',
          SUBTITLE: 'Details of each school',
          SCHOOL_ID: 'School Id',
          SCHOOL_NAME: 'School Name',
          SCHOOL_ADDRESS: 'School Address',
          WARD: 'Ward/Commune',
          DISTRICT: 'District',
          CITY: 'City/Province',
          START: 'Start',
          END: 'End',
        },
        roads: {
          TITLE: 'Roads',
          SUBTITLE: 'Details of selected roads',
          ADD_NEW: 'Add new road',
          CHOOSE_SCHOOL_INVITE: 'Choose a school to begin',
          CHOOSE_SCHOOL: 'Choose a school',
          NAME: 'Road name',
          LAT1: 'Latitude 1',
          LAT2: 'Latitude 2',
          LONG1: 'Longitude 1',
          LONG2: 'Longitude 2',
          SCHOOL: 'School Code',
          PLOT: 'Extra points to plot',
          COUNT: 'Count',
          ACTIONS: 'Actions',
        },
        topbar: {
          SIGN_OUT_TITLE: 'Do you want to sign out?',
          SIGN_OUT_CONFIRM: 'If you really want to sign out, please click OK, otherwise, click Cancel.',
          CANCEL_BUTTON: 'Cancel',
        },
        info: {
          TITLE: 'Info',
          SUBTITLE: 'Introduction to Bike Safely project',
        },
        gallery: {
          NEXT: 'Next image',
          PREV: 'Previous image',
          PAGE: 'Page',
        },
        ridesafe: {
          TITLE: 'Bike Safely',
          SUBTITLE: "Let's ride safely",
        },
        public: {
          PUBLIC: 'Dashboard',
          WELCOME: 'Welcome to your public dashboard',
          FILTER_LABEL: 'Data are filtered',
          FILTER_SELECT: 'Filter data by',
          CITY: 'City/Town',
          AREA: 'District',
          SUB_AREA: 'Ward/Commune',
          SCHOOL: 'School',
          SWITCH_TO: 'Switch to',
          SW_LOCATION: 'Location',
          SW_TIME: 'Time',
          SW_POSTTEXT: 'Filter',
          DENSITY: 'Density',
          CANCEL: 'Cancel',
          CONFIRM: 'Confirm',
          TIME_FILTER_LABEL: 'By Time',
          LOCATION_FILTER_LABEL: 'By Location',
          FROM: 'From ',
          TO: ' to ',
          FILTER_TITLE: 'Filter',
          FILTER_DESC:
            'In this toolbar, you can select the desired categories. After filtering, the information will be displayed in the Results Chart box.',
          SCHOOL_INFO: 'School Info',
          SCHOOL_NAME: 'School',
          SCHOOL_ADDRESS: 'Address',
          TIME_INFO: 'Time Range',
          TIME_START: 'From',
          TIME_END: 'To',
          ADDITIONAL_INFO: 'Additional information',
        },
        plainmap: {
          SCHOOL_NO: 'School',
          SCHOOL_ROUTES: 'Routes to school',
          SCHOOL_ROADS: 'Monitored roads',
          SCHOOL_RESULTS: 'Results',
        },
        team: {
          TITLE: 'All Users',
          SUBTITLE: 'Managing user information',
          ADD_NEW: 'Add new user',
          NAME: 'Name',
          USERNAME: 'Username',
          PASSWORD: 'Password',
          ACCESS_LEVEL: 'Access Level',
          ACTIONS: 'Actions',
        },
      }
    : {
        dashboard: {
          DASHBOARD: 'Tổng hợp',
          WELCOME: 'Hiển thị thông tin chọn lọc',
          TOTAL_DISTANCE: 'Tổng quãng đường',
          TOTAL_DISTANCE_DESC: 'Ở thanh công cụ này, bạn có thể xem tổng khoảng cách đoạn đường mà học sinh đã di chuyển.',
          TRAVEL: 'đã di chuyển',
          FROM: 'từ',
          TO: 'đến',
          DATA_COLLECTED: 'Dữ liệu thu thập',
          DEVICES: 'thiết bị',
          RESULT_CHARTS: 'Kết quả khảo sát',
          RESULT_NO: 'STT',
          RESULT_ROUTES: 'Tuyến đường khảo sát',
          RESULT_RATIOS: 'Tỉ lệ (%)',
          RESULT_RESULTS: 'Kết quả',
          TOTAL_TRAVEL: 'Tổng quãng đường',
          LOCALE: 'vi-VI',
          CO2_EMISSION: 'Giảm phát thải GHG',
          CO2_EMISSION_2: 'tương đương',
          CO2_UNIT: 'tấn CO2e',
          CO2_EMISSION_DESC:
            'Ở thanh công cụ này, bạn có thể xem lượng khí thải tương đương cacbon đioxit (CO2e) ra môi trường giảm được qua việc di chuyển bằng xe đạp.',
          SCHOOL_INFO: 'Thông tin trường học (địa điểm) đã chọn',
          SCHOOL_NAME: 'Tên trường',
          SCHOOL_ADDRESS: 'Địa chỉ',
          SCHOOL_TIME: 'Thời gian khảo sát',
          TIME_INFO: 'Khoảng thời gian đã chọn',
          TIME_START: 'Từ',
          TIME_END: 'Đến',
          ADDITIONAL_INFO: 'Thông tin bổ sung',
          CLUSTER_MAP: 'Bản đồ Nhóm điểm',
          ROUTES_MAP: 'Bản đồ Lộ trình',
          FULL_MAP: 'Mở rộng bản đồ',
          NORMAL_MAP: 'Thu nhỏ bản đồ',
          SHOW_SCHOOL: 'Hiển thị vị trí các trường học',
          HIDE_SCHOOL: 'Ẩn vị trí các trường học',
          SHOW_ROUTE: 'Hiển thị tất cả lộ trình',
          HIDE_ROUTE: 'Ẩn tất cả lộ trình',
          SHOW_ENDS: 'Hiển thị thông tin lộ trình',
          HIDE_ENDS: 'Ẩn thông tin lộ trình',
          LEGEND: 'CHÚ GIẢI',
          LEGEND_ROUTE: 'Lộ trình di chuyển',
          LEGEND_START: 'Điểm bắt đầu lộ trình',
          LEGEND_END: 'Điểm kết thúc lộ trình',
          LEGEND_SCHOOL: 'Vị trí một trường học',
        },
        sidebar: {
          WELCOME: 'Xin chào',
          MAP: 'Bản đồ',
          CHART: 'Biểu đồ',
          DATA: 'Dữ liệu',
          OTHER: 'Khác',
          CAMPAIGN: 'Trường học',
          DASHBOARD: 'Tổng hợp',
          TEAM: 'Người dùng',
          ROADS: 'Tuyến đường',
          FAQ: 'Câu hỏi thường gặp',
          CONTACT: 'Liên hệ',
          INFO: 'Giới thiệu',
          KNOWLEDGE: 'Kiến thức',
          PUBLIC: 'Tổng hợp',
        },
        faq: {
          TITLE: 'Câu hỏi thường gặp',
          SUBTITLE: 'Giải đáp thông tin - Hướng dẫn sử dụng',
        },
        login: {
          SIGNIN: 'Đăng nhập',
          CANCEL: 'Quay lại',
          USER: 'Tên đăng nhập',
          PASS: 'Mật khẩu',
          ERROR_401: 'Đăng nhập thất bại. Vui lòng kiểm tra thông tin đăng nhập',
          ERROR_GENERAL: 'Đã có lỗi trong quá trình đăng nhập. Vui lòng thử lại sau',
          ERROR_USERNAME: 'Vui lòng nhập tên đăng nhập',
          ERROR_PASSWORD: 'Vui lòng nhập mật khẩu',
        },
        map: {
          TITLE: 'Bản đồ',
          SUBTITLE: 'Chi tiết các tuyến đường đã ghi nhận',
          FROM: 'Từ',
          TO: 'Đến',
          LOCALE: 'vi-VI',
        },
        campaign: {
          TITLE: 'Trường học',
          SUBTITLE: 'Thông tin của từng trường',
          SCHOOL_ID: 'Mã trường',
          SCHOOL_NAME: 'Tên trường',
          SCHOOL_ADDRESS: 'Địa chỉ trường',
          WARD: 'Phường/Xã',
          DISTRICT: 'Quận/Huyện',
          CITY: 'Thành phố/Tỉnh',
          START: 'Bắt đầu',
          END: 'Kết thúc',
        },
        roads: {
          TITLE: 'Tuyến đường',
          SUBTITLE: 'Thông tin các tuyến đường được chọn khảo sát',
          ADD_NEW: 'Thêm tuyến đường mới',
          CHOOSE_SCHOOL_INVITE: 'Chọn 1 trường học để bắt đầu',
          CHOOSE_SCHOOL: 'Chọn trường học',
          NAME: 'Tên đường',
          LAT1: 'Vĩ độ 1',
          LAT2: 'Vĩ độ 2',
          LONG1: 'Kinh độ 1',
          LONG2: 'Kinh độ 2',
          SCHOOL: 'Mã Trường học',
          PLOT: 'Các điểm toạ độ phụ',
          COUNT: 'Số lượt',
          RATIO: 'Tỉ lệ',
          COLOR: 'Mã màu',
          ACTIONS: 'Thao tác',
        },
        topbar: {
          SIGN_OUT_TITLE: 'Bạn muốn rời khỏi đây?',
          SIGN_OUT_CONFIRM: 'Nếu bạn muốn rời đi, vui lòng bấm nút OK. Nếu không, hãy bấm nút Bỏ qua.',
          CANCEL_BUTTON: 'Bỏ qua',
        },
        info: {
          TITLE: 'Giới thiệu',
          SUBTITLE: 'Về dự án Đạp xe đến trường an toàn',
        },
        gallery: {
          NEXT: 'Ảnh tiếp theo',
          PREV: 'Ảnh trước',
          PAGE: 'Trang',
        },
        ridesafe: {
          TITLE: 'Đạp xe an toàn',
          SUBTITLE: 'Lưu ý để đạp xe được an toàn',
        },
        public: {
          PUBLIC: 'Tổng hợp',
          WELCOME: 'Chào mừng đến với cổng thông tin',
          FILTER_LABEL: 'Dữ liệu đang lọc',
          FILTER_SELECT: 'Chọn dữ liệu mới theo',
          CITY: 'Thành phố/Tỉnh',
          AREA: 'Quận/Huyện',
          SUB_AREA: 'Phường/Xã/Thị trấn',
          SCHOOL: 'Trường học',
          SWITCH_TO: 'Chuyển sang lọc theo',
          SW_LOCATION: 'Địa điểm',
          SW_TIME: 'Thời gian',
          SW_POSTTEXT: '',
          DENSITY: 'Mật độ',
          CANCEL: 'Huỷ',
          CONFIRM: 'Chọn',
          TIME_FILTER_LABEL: 'Theo thời gian',
          LOCATION_FILTER_LABEL: 'Theo địa điểm',
          FROM: 'Từ ',
          TO: ' đến ',
          FILTER_TITLE: 'Bộ lọc',
          FILTER_DESC:
            'Ở thanh công cụ này, bạn có thể lựa chọn các hạng mục mong muốn. Sau khi chọn lọc, thông tin sẽ hiển thị ở ô Biểu đồ kết quả.',
          SCHOOL_INFO: 'Thông tin trường học (địa điểm) đã chọn',
          SCHOOL_NAME: 'Tên trường',
          SCHOOL_ADDRESS: 'Địa chỉ',

          TIME_INFO: 'Khoảng thời gian đã chọn',
          TIME_START: 'Từ',
          TIME_END: 'Đến',
          ADDITIONAL_INFO: 'Thông tin bổ sung',
        },
        plainmap: {
          SCHOOL_NO: 'Trường số',
          SCHOOL_ROUTES: 'Lộ trình đến trường',
          SCHOOL_ROADS: 'Tuyến đường khảo sát',
          SCHOOL_RESULTS: 'Kết quả khảo sát',
        },
        team: {
          TITLE: 'Người dùng',
          SUBTITLE: 'Quản lý thông tin người dùng',
          ADD_NEW: 'Thêm người dùng mới',
          NAME: 'Tên người dùng',
          USERNAME: 'Tên đăng nhập',
          PASSWORD: 'Mật khẩu',
          ACCESS_LEVEL: 'Cấp độ',
          ACTIONS: 'Thao tác',
        },
      }),
});

// export const ColorModeContext = createContext({
//   toggleColorMode: () => {},
// });

// Create a context with a default value
const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('vn'); // Default language is English

  const switchLanguage = (lang) => {
    setLanguage(lang);
  };

  return <LanguageContext.Provider value={{ language, switchLanguage }}>{children}</LanguageContext.Provider>;
};

// Custom hook for using the LanguageContext
export const useLanguage = () => useContext(LanguageContext);
