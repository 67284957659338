import { useState } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { format } from 'date-fns';
import viLocale from 'date-fns/locale/vi';
import Heatmap from '../../components/maps/Heatmap';
import Header from '../../components/Header';
import Cluster from '../../components/maps/Cluster';
import Heatmapv3 from '../../components/maps/Heatmapv3';
import { useLanguage, langTokens } from '../../language';
// Icons
import DateRangeIcon from '@mui/icons-material/DateRangeOutlined';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

const Map = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { language } = useLanguage();
  const strLang = langTokens(language).map;
  const mapData = JSON.parse(localStorage.initData);
  const [isExpanded, setIsExpanded] = useState('allmap');

  const toggleExpand = (buttonId) => {
    setIsExpanded(isExpanded === buttonId.id ? 'allmap' : buttonId.id);
  };
  const FullScreenButton = (_id) => {
    return (
      <Button
        variant='outlined'
        onClick={() => toggleExpand(_id)}
        sx={{ position: 'absolute', top: '80px', left: '10px', zIndex: 99999, minWidth: '34px', width: '34px', bgcolor: '#ffffffcc' }}>
        {isExpanded === 'allmap' ? <FullscreenIcon /> : <FullscreenExitIcon />}
      </Button>
    );
  };
  const MapBox = (_id) => {
    const _mapId = _id._mapId;
    return (
      <Box
        position={'relative'}
        gridColumn={isExpanded === _mapId ? 'span 2' : 'span 1'}
        gridRow={isExpanded === _mapId ? 'span 2' : 'span 1'}>
        <FullScreenButton id={_mapId} />
        {_mapId === 'map1' && <Heatmap dataArray={mapData.filteredObj} />}
        {/* {_mapId === 'map2' && <Cluster dataArray={mapData.filteredObj} plotline={0} plotheat={1} plotCombined={1} />}
        {_mapId === 'map3' && <Heatmapv3 dataArray={mapData.filteredObj} />} */}
      </Box>
    );
  };
  const _timeRange = JSON.parse(localStorage.getItem('timeRange'));
  let _fr = new Date(parseFloat(_timeRange.from));
  let _to = new Date(parseFloat(_timeRange.to));

  const shortDate = (_d) => {
    return format(_d, 'EEE d/LL/y', strLang.LOCALE === 'vi-VI' ? { locale: viLocale } : '');
  };

  return (
    <Box m='-53px 0 0 20px'>
      <Header title={strLang.TITLE} subtitle={strLang.SUBTITLE} />
      <Box p='0 5px' mb='30px' width={'35%'} borderBottom={'solid 1px ' + colors.greenAccent[700]} display='flex' alignItems={'center'}>
        <DateRangeIcon sx={{ color: colors.greenAccent[600], fontSize: '22px' }} />
        <Typography variant='h6' ml='1ch'>
          {strLang.FROM}
        </Typography>
        <Typography variant='h5' ml='1ch' fontWeight='bold'>
          {shortDate(_fr)}
        </Typography>
        <Typography variant='h6' ml='6ch'>
          {strLang.TO}
        </Typography>
        <Typography variant='h5' ml='1ch' fontWeight='bold'>
          {shortDate(_to)}
        </Typography>
      </Box>
      <Box display='grid' gridTemplateColumns='repeat(2, 1fr)' gridAutoRows='calc(100vh / 2 - 100px)' gap={'10px'}>
        {(isExpanded === 'map1' || isExpanded === 'allmap') && <MapBox _mapId='map1' />}
        {(isExpanded === 'map2' || isExpanded === 'allmap') && <MapBox _mapId='map2' />}
        {(isExpanded === 'map3' || isExpanded === 'allmap') && <MapBox _mapId='map3' />}
      </Box>
    </Box>
  );
};

export default Map;
