import React, { useEffect, useState, Fragment } from 'react';
import { MapContainer, Polyline, LayersControl, LayerGroup, Marker, Popup, useMap } from 'react-leaflet';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useLanguage } from '../../language';
import viLocale from 'date-fns/locale/vi';
import { format } from 'date-fns';
import Basemaps from './Basemaps';
import SchoolMarker from './SchoolMarker';
import { phuThienCoord as phuThien } from '../../data/geoCoding';
// Icons

const Heatmap = ({ dataArray, quarterIndex, showEnds, showSchools, overlaysEnabled }) => {
  // console.log('Heatmap');
  const { language } = useLanguage();
  const { Overlay } = LayersControl;
  const isAdmin = JSON.parse(localStorage.getItem('user'))?.role === 'admin';
  const currentSchool = localStorage.getItem('currentSchool') ? JSON.parse(localStorage.getItem('currentSchool')) : null;
  // console.log(currentSchool);
  const quarters = ['05:00-08:00', '10:00-12:00', '12:00-14:00', '16:00-19:00'];
  const data = quarterIndex === null ? dataArray : JSON.parse(localStorage.getItem('initData'))?.filteredObj || null;

  const FitBoundsPolylines = ({ polylines }) => {
    const map = useMap();
    useEffect(() => {
      if (polylines.length > 0) {
        const bounds = L.latLngBounds(polylines.flat());
        map.fitBounds(bounds);
      }
    }, [polylines, map]);

    return null;
  };
  //   const latLongArr = [];
  //   arr.forEach((e) => {
  //     latLongArr.push([e.Lat, e.Long]);
  //   });
  //   return latLongArr;
  // };
  const combinedLatLong = (_data) => {
    const latLong = {};
    Object.keys(_data).forEach((key) => {
      if (!latLong[key]) {
        latLong[key] = [];
      }
      _data[key].forEach((e) => {
        latLong[key].push([Number.parseFloat(e.Lat).toFixed(5), Number.parseFloat(e.Long).toFixed(5)]);
      });
    });
    return latLong;
  };
  const combinedData = (_data) => {
    const combinedData = {};

    for (const entity in _data) {
      for (const date in _data[entity]) {
        if (!combinedData[date]) {
          combinedData[date] = [];
        }
        combinedData[date] = combinedData[date].concat(_data[entity][date]);
      }
    }
    return combinedData;
  };

  // console.log(_combinedLatLong);

  var LeafIcon = L.Icon.extend({
    options: {
      iconSize: [12, 21],
      iconAnchor: [6, 21],
      popupAnchor: [0, -21],
    },
  });
  var startIcon = new LeafIcon({ iconUrl: '/assets/start2.png' }),
    finishIcon = new LeafIcon({ iconUrl: '/assets/finish2.png' });

  const schools = JSON.parse(localStorage.getItem('schoolData')).flatMap((city) =>
    city.areas.flatMap((area) =>
      area.subareas.flatMap((subarea) => {
        return subarea.schools.map((school) => ({
          id: school[0],
          schoolName: school[1],
          Lat: school[2],
          Lon: school[3],
          address: school[4],
          // ward: `${subarea.name} (${subarea.id})`,
          // district: `${area.name} (${area.id})`,
          // city: `${city.name} (${city.id})`,
          // startDate: school[8], // Initialize start and end dates
          // endDate: school[9],
        }));
      })
    )
  );

  const _combinedData = combinedData(dataArray);
  const _combinedLatLong = combinedLatLong(_combinedData);
  const devLabel = language === 'en' ? 'Route of device' : 'Lộ trình t/bị';
  return (
    <Box width={'100%'} height={'100%'} id='map'>
      <MapContainer
        style={{ borderRadius: '10px' }}
        center={phuThien.center}
        zoom={12}
        minZoom={10}
        maxZoom={18}
        maxBoundsViscosity={1.0}
        maxBounds={
          JSON.parse(localStorage.getItem('user'))?.username === process.env.REACT_APP_SUPER_ADMIN
            ? null
            : L.latLngBounds(phuThien.sW, phuThien.nE)
        }>
        <LayersControl position='topright'>
          {data !== null ? (
            quarterIndex === null ? (
              Object.keys(data).map((key) => {
                //key = deviceID, eg:1,2,...
                const dataArray1 = data[key];
                const latLongArr = dataArray1.map((e) => [e.Lat, e.Long]);

                return (
                  <Overlay name={`${devLabel} ${key}`} key={`OVL-${key}`} checked={overlaysEnabled}>
                    <LayerGroup>
                      {/* <Polyline positions={latLongArray2} pathOptions={{ color: 'blue', opacity: 0.15 }} /> */}
                      <Polyline positions={latLongArr} pathOptions={{ color: 'blue', opacity: 0.15 }} />
                      {showEnds && (
                        <>
                          <Marker position={latLongArr[latLongArr.length - 1]} icon={finishIcon}>
                            {isAdmin && (
                              <Popup>
                                ID:{key} @[{format(new Date(dataArray1[dataArray1.length - 1].time), 'HH:mm')}]
                              </Popup>
                            )}
                          </Marker>
                          <Marker position={latLongArr[0]} icon={startIcon}>
                            {isAdmin && (
                              <Popup>
                                ID:{key} @[{format(new Date(dataArray1[0].time), 'HH:mm')}]
                              </Popup>
                            )}
                          </Marker>
                        </>
                      )}
                    </LayerGroup>
                  </Overlay>
                );
              })
            ) : (
              Object.keys(data).map((day) => {
                // day: e.g. 2024-09-15
                const quarter = quarters[quarterIndex - 1];
                const dataArray1 = data[day][quarter];
                return (
                  <Overlay
                    name={format(new Date(day), 'eee, dd/LL/yy', language !== 'en' ? { locale: viLocale } : '')}
                    key={day}
                    checked={overlaysEnabled}>
                    <LayerGroup>
                      {dataArray1 &&
                        Object.keys(dataArray1).map((key) => {
                          //key = deviceID, eg:1,2,...
                          const dataArray2 = dataArray1[key];
                          const latLongArr = dataArray2.map((e) => [e.Lat, e.Long]);
                          return (
                            <Fragment key={key}>
                              <Polyline positions={latLongArr} pathOptions={{ color: 'blue', opacity: 0.15 }} />
                              {showEnds && (
                                <>
                                  <Marker position={latLongArr[latLongArr.length - 1]} icon={finishIcon}>
                                    {isAdmin && (
                                      <Popup>
                                        ID:{key} @[{format(new Date(dataArray2[dataArray2.length - 1].time), 'HH:mm')}]
                                      </Popup>
                                    )}
                                  </Marker>
                                  <Marker position={latLongArr[0]} icon={startIcon}>
                                    {isAdmin && (
                                      <Popup>
                                        ID:{key} @[{format(new Date(dataArray2[0].time), 'HH:mm')}]
                                      </Popup>
                                    )}
                                  </Marker>
                                </>
                              )}
                            </Fragment>
                          );
                        })}
                    </LayerGroup>
                  </Overlay>
                );
              })
            )
          ) : (
            <></>
          )}
          <Overlay name={language === 'en' ? "Schools' location" : 'Vị trí các trường THCS'} key={'schools'} checked={showSchools}>
            <LayerGroup>
              {currentSchool ? (
                <SchoolMarker
                  position={[currentSchool.Lat, currentSchool.Lon]}
                  address={currentSchool.address}
                  name={currentSchool.schoolName}
                />
              ) : (
                schools.map((school, idx) => (
                  <SchoolMarker key={idx} position={[school.Lat, school.Lon]} address={school.address} name={school.schoolName} />
                ))
              )}
            </LayerGroup>
          </Overlay>
          <Basemaps />
        </LayersControl>

        <FitBoundsPolylines polylines={Object.values(_combinedLatLong)} />
      </MapContainer>
    </Box>
  );
};

export default Heatmap;

/* {places.map((place, index) => showBoundingBoxes({ place }, index))} */

/* {Object.keys(_combinedLatLong)
    .sort((b, a) => {
      return new Date(a) - new Date(b);
    })
    .map((key, index) => (
      <Overlay name={dayOfWeek(key) + ' ' + key + ' - Polyline'} key={index} checked={plotlineTrue ? true : false}>
        <LayerGroup>
          <Polyline positions={_combinedLatLong[key]} color={pathColor[index % 7]} />
          {_combinedLatLong[key].map((item, index) => {
            if (index === 0) {
              return (
                <Marker position={item} key={index} icon={startIcon}>
                  <Popup>Start point</Popup>
                </Marker>
              );
            } else if (index === _combinedLatLong[key].length - 1) {
              return (
                <Marker position={item} key={index} icon={finishIcon}>
                  <Popup>Finish point</Popup>
                </Marker>
              );
            } else {
              return <CircleMarker key={index} center={item} radius={3} color='black' weight={1} opacity={0.25} fillColor />;
            }
          })}
        </LayerGroup>
      </Overlay>
    ))} */

/*const showBoundingBoxes = ({ place }, index) => {
  const [lat0, lat1, long0, long1] = place.boundingbox.map(Number);
  const bBoxCorners = [
    [lat0, long0],
    [lat0, long1],
    [lat1, long1],
    [lat1, long0],
    [lat0, long0], // Close the loop
  ];

  return <Polyline positions={bBoxCorners} key={index} />;
};*/

// const pathColor = [
//   '#ea5545',
//   '#f46a9b',
//   '#ef9b20',
//   '#edbf33',
//   '#ede15b',
//   '#bdcf32',
//   '#87bc45',
//   '#27aeef',
//   '#b33dc6',
//   '#e60049',
//   '#0bb4ff',
//   '#50e991',
//   '#e6d800',
//   '#9b19f5',
//   '#ffa300',
//   '#dc0ab4',
//   '#b3d4ff',
//   '#00bfa0',
// ];
